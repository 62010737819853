import { For } from 'solid-js';

import type { ContentDto } from '~/modules/api';
import { CarouselGallery, Tile } from '../shared';

interface LearnFromexpertsProps {
  items: ContentDto[];
}

export function LearnFromExperts(props: LearnFromexpertsProps) {
  return (
    <CarouselGallery
      id="education"
      heading={{ title: 'Lessons From The Best' }}
      hideArrows
    >
      <For each={props.items}>
        {item => (
          <li class="swiper-slide df-scrollable-list-item-3-col">
            <Tile preset="HorizontalTilePoster" content={item} />
          </li>
        )}
      </For>
    </CarouselGallery>
  );
}
